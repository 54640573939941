<template>
    <div class="gallery-container">
        <Gallery :datasource="gallery"></Gallery>
    </div>
</template>

<script>
import Gallery from '@/components/Gallery'
export default {
    name: 'Test',
    components: {
        Gallery
    },
    data() {
        return {
            gallery: [
                {
                    title: '微信H5商铺',
                    keyword1: '轻量级移动经营体验',
                    keyword2: '即开即用易传播',
                    img: 'https://www.xiaoe-tech.com/images/onlineEducation/img_H5.png'
                },
                {
                    title: '微信小程序',
                    keyword1: '一键生成，无需开发',
                    keyword2: '快速拥有自己的小程序店铺',
                    img: 'https://www.xiaoe-tech.com/images/onlineEducation/img_H5.png'
                },
                {
                    title: 'PC独立官网',
                    keyword1: '具备产品交付和商业变现能力',
                    keyword2: '建立专属品牌官网',
                    img: 'https://www.xiaoe-tech.com/images/onlineEducation/img_H5.png'
                },
                {
                    title: '鹅学习APP',
                    keyword1: '学员专属APP',
                    keyword2: '无需开发也能轻松交付课程',
                    img: 'https://www.xiaoe-tech.com/images/onlineEducation/img_H5.png'
                },
                {
                    title: 'API/SDK开发',
                    keyword1: '联接多个渠道，打通自有APP',
                    keyword2: '实现个性化自主开发',
                    img: 'https://www.xiaoe-tech.com/images/onlineEducation/img_H5.png'
                }
            ]
        }
    },
}
</script>

<style lang="less" scoped>

</style>